
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import environment from 'src/environments/environment';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {    
    const url = request.url;
    if (!(url.includes(environment.URL_LOGIN) || url.includes(environment.URL_SIGNUP))) {      
      return next.handle(request);
    }
    return next.handle(request).pipe(
      map((event:any) => {
        if (event instanceof HttpResponse) {                                
          if(request.body.autoLogin) {
            this.keepCredentials(request);  
          }
          this.keepAccessToken(event);
        }         
        return event;
      }),      
    );
  }

  private keepAccessToken(res: HttpResponse<any>) {
    localStorage.setItem('id', res.body.user.id);
    localStorage.setItem('token', res.body.token);
  }

  private keepCredentials(request: HttpRequest<any>) {
    localStorage.setItem('email', request.body.email);
    localStorage.setItem('password', request.body.password);
    localStorage.setItem('autoLogin', request.body.autoLogin);
  }
}