import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(this.doNotneedToken(request)) {
      return next.handle(request);
    }

    const token = localStorage.getItem('token') as string;    
    if (!token) {      
      return next.handle(request);      
    }
        
    return next.handle(request.clone({
      setHeaders: {
        Authorization: token,
      }
    }));
  }
  
  doNotneedToken(request: HttpRequest<any>) {
    return request.url.includes("i18n");
  }
}

