
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {        
    return next.handle(request).pipe(
      catchError((error: HttpResponse<any>) => {                                                      
        if ([0, HttpStatusCode.Unauthorized].includes(error.status)) {          
          localStorage.clear();
          this.router.navigate(['auth'], {replaceUrl: true});
          return EMPTY;
        }        
        return throwError( () => error);
      }),      
    );
  }

}