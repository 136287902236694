import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonApp, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterOutlet, IonRouterLink } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, heartOutline, heartSharp, archiveOutline, archiveSharp, trashOutline, trashSharp, warningOutline, warningSharp, bookmarkOutline, bookmarkSharp, homeOutline, serverOutline, peopleOutline } from 'ionicons/icons';
import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@capacitor/splash-screen';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule, IonApp, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterLink, IonRouterOutlet],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/', icon: 'home', fragment: 'main' },
    { title: 'Services', url: '/', icon: 'server', fragment: 'services' },
    { title: 'Solutions', url: '/', icon: 'trash', fragment: 'solutions' },
    { title: 'Partners', url: '/', icon: 'people', fragment: 'partners' },
    { title: 'Jobs', url: '/', icon: 'heart', fragment: 'jobs' },
    // { title: 'Blog', url: '/', icon: 'warning', fragment: 'blog' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {
    this.setupSplashScreen();
    addIcons({ 
      homeOutline,
      serverOutline,
      peopleOutline,      
      mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, heartOutline, heartSharp, archiveOutline, archiveSharp, trashOutline, trashSharp, warningOutline, warningSharp, bookmarkOutline, bookmarkSharp });
  }

  async setupSplashScreen() {
    await SplashScreen.show({
      showDuration: 3000,
      autoHide: true,
    });
  }
}
