import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import environment from 'src/environments/environment';

const ACCEPTABLE_DURATION = 200;
const LONG_DURATION = 1000;

@Injectable()
export class RequestTimingInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {    
    if(!['local', 'qa', 'docker'].includes(environment.ENV)) {
      return next.handle(request);
    }
    const startTime = Date.now();
    return next.handle(request).pipe(
      finalize(() => {
        const endTime = Date.now();
        const duration = endTime - startTime;
        if (duration > ACCEPTABLE_DURATION) {
          const msg = `${request.urlWithParams.replace(environment.BASE_URL, '')} took ${duration}ms`;
          if(duration > LONG_DURATION) {
            console.warn(msg);
          } else {
            console.debug(msg);
          }
        }
      })
    );
  }
}