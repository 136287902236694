import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import environment from 'src/environments/environment';

@Injectable()
export class HostInterceptor implements HttpInterceptor {
  
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if(!request.url.startsWith('/')) {
      return next.handle(request);
    }
    const apiRequest = request.clone({
      url: `${environment.BASE_URL}${request.url}`,      
    });
    return next.handle(apiRequest);
  }
}