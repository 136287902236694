import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';
import { LoadingService } from '@shared/services/loading.service';

@Injectable()
export class LoadInterceptor implements HttpInterceptor {  
  constructor(private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    if (request.url.includes('talk')) {
        return next.handle(request);
    }
    this.loadingService.show();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {                
        this.loadingService.hide();
        return throwError( () => error);
      }),
      filter(event => event.type === HttpEventType.Response),
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }
}


