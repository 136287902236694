import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading: Promise<HTMLIonLoadingElement> | null = null;

  constructor(private loadingController: LoadingController) {}

  async show(message: string = 'Loading...') {                
    if (!this.loading) {      
      this.loading = this.loadingController.create({
        message: message,        
        cssClass: 'ion-loading',        
        duration: 3000

      }).then(loading => {
        loading.present();
        return loading;
      });
    }
    return this.loading;
  }

  async hide() {
    // console.log('hide');    
    if (this.loading) {      
      // console.log('this.loading: ' + this.loading);
      const loading = await this.loading;
      await loading.dismiss();
      this.loading = null; // Reset the loading reference
    }
  }
}
