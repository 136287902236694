import environment from './environments/environment';
import { enableProdMode } from '@angular/core';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { routes } from './app/app.routes';
import { LOCALE_ID, importProvidersFrom } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withJsonpSupport,
  withXsrfConfiguration
} from '@angular/common/http';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { CredentialsInterceptor } from '@shared/interceptors/credentials.interceptor';
import { HostInterceptor } from '@shared/interceptors/host.interceptor';
import { LoadInterceptor } from '@shared/interceptors/load.interceptor';
import { ParameterSanitizerInterceptor } from '@shared/interceptors/parameter.sanitizer';
import { RequestTimingInterceptor } from '@shared/interceptors/time.interceptor';
import { UnauthorizedInterceptor } from '@shared/interceptors/unauthorized.interceptor';

registerLocaleData(localePt);

console.info(`environment: ${environment.ENV}`);
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID, useValue: 'pt-BR'
    }
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParameterSanitizerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestTimingInterceptor,
      multi: true,
    },
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(IonicModule.forRoot({})),
    provideHttpClient(
      withXsrfConfiguration({ cookieName: '', headerName: '' }),
      withJsonpSupport()
    ),
    provideIonicAngular(),
    provideRouter(routes),
    provideAnimations(),
  ],
});
