export default {    
  APP_NAME: "Crezendus",
  URL_SIGNUP: '/auth/signup/',
  URL_SIGN_OFF: '/auth/signoff',
  URL_LOGIN: '/auth/login/',
  URL_FORGOT : '/auth/forgot/',  
  URL_RESET : '/auth/reset/',  
  URL_LOGOUT : '/auth/logout/',  
  URL_USERS: '/users/',
  URL_PROFILES: '/profiles/',
  URL_CHATBOTS: '/chatbots/',  
  URL_PROFESSIONALS: '/professionals/',
  URL_TEAMS: '/teams/',
  URL_PLAYERS: '/players/',  
  URL_PLANS: '/plans/',
  URL_SUBSCRIPTIONS: '/subscriptions/',
  URL_PAYMENTS: '/payments/',
  URL_HEADQUARTERS: '/headquarters/',
  URL_EVENTS: '/confrontations/events/',
  URL_COMPETITIONS: '/competitions/',
  URL_MATCHES: '/matches/',
  URL_LINEUPS: '/lineups/',  
  URL_SCORERS: '/competitions/scorers', 
  URL_STANDINGS: '/competitions/standings', 
  URL_ROSTERS: '/rosters/',
  URL_NOTIFICATIONS: '/notifications/',    
  URL_RECIPIENTS: '/notifications/recipients/',    
  URL_PRODUCTS: '/products/',
  URL_STATS: '/stats/',
  URL_STATS_TEAMS: '/stats/teams',  
  URL_STATS_CONFRONTATIONS: '/stats/confrontations',  
  URL_STATS_PLAYERS: '/stats/players',
  TOAST_DURATION: 2000
};


// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
