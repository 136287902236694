import { tap } from 'rxjs/operators';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class ParameterSanitizerInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {    
    const params: any = {};
    request.params.keys().forEach( k => {
      const value = request.params.get(k);      
      if (![null, undefined, ''].includes(value)) {
        params[k] = value;
      }
    });    
    return next.handle(request.clone({
      params: new HttpParams({fromObject:params})
    }));
  }
}